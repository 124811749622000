import { useRouteError } from "react-router"
import FullHeightContainer from "../components/FullHeightContainer"
import { Typography, Button, ButtonGroup } from "@mui/material"
import { NavLink } from "react-router-dom"
import BungalowIcon from '@mui/icons-material/Bungalow';
import { useNavigate } from "react-router";
import ReplyIcon from '@mui/icons-material/Reply';



const ErrorPage = () => {
   const error = useRouteError()
   console.log(error);
   return (
      <FullHeightContainer gap={2}>
         <Typography variant={'h1'}>Упс!</Typography>
         <Typography variant={'h4'}>Щось пішло не так</Typography>
         <Typography variant={'body1'} color="text.secondary">{error.message}</Typography>
         <ButtonGroup
            sx={{ gap: 1 }}
            size='large'
         >
            <NavLink to={-1}>
               <Button startIcon={<ReplyIcon />} variant="contained">
                  назад
               </Button>
            </NavLink>
            <NavLink to={'/'}>
               <Button endIcon={<BungalowIcon />} variant="contained">
                  на головну
               </Button>
            </NavLink>
         </ButtonGroup>
      </FullHeightContainer>
   )
}

export default ErrorPage