import CustomRouterProvider from "./route/CustomRouterProvider";
import Wrapper from "./route/Wrapper";


function App() {



  return (
    <CustomRouterProvider>
      <Wrapper />
    </CustomRouterProvider>
  );
}

export default App;
