import {NavLink} from 'react-router-dom'

const Start = ()=>{
   return(
      <>
      Start
      </>
   )
}

export default Start