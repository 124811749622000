import { createBrowserRouter } from "react-router-dom"
import Wrapper from "./Wrapper"
import Start from '../pages/Start'
import Blog from '../pages/Blog'
import Blogs from '../pages/Blogs'
import fetchBlogs from "../api/fetchBlogs"
import fetchBlog from "../api/fetchBlog"
import NotFound from "../pages/NotFound"
import ErrorPage from '../pages/ErrorPage'
import Login from "../components/Auth/Login"
import Register from "../components/Auth/Register"
import Forgot from "../components/Auth/Forgot"

const RouterConfig = createBrowserRouter([
   {
      path: '/',
      element: <Wrapper />,
      errorElement:<ErrorPage/>,
      children: [
         {
            element: <Start />,
            errorElement:<ErrorPage/>,
            index: true,
         },
         {
            element: <Blogs />,
            errorElement:<ErrorPage/>,
            path: '/blog',
            loader: fetchBlogs
         },
         {
            element: <Blog />,
            errorElement:<ErrorPage/>,
            path: '/blog/:blogId',
            loader: fetchBlog
         },
         {
            element: <Login />,
            errorElement:<ErrorPage/>,
            path: '/login',
         },
         {
            element: <Register />,
            errorElement:<ErrorPage/>,
            path: '/register',
         },
         {
            element: <Forgot />,
            errorElement:<ErrorPage/>,
            path: '/forgot',
         },
         {
            element: <NotFound/>,
            path: '*',
         },
      ]
   },

])

export default RouterConfig
