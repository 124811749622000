import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { grey } from '@mui/material/colors';

export default function StartRating({value}) {
   const st = {
      '& .MuiRating-iconEmpty': {
         color: grey[700],
      },
      
   }

   const handleChange = (e,t)=>{
      console.log(t);
   }
   return (
      <Stack sx={st}  color={'#fff'} spacing={1}>
         <Rating onChange={handleChange} value={value} name="half-rating" defaultValue={2.5} precision={0.5} />
      </Stack>
   );
}