import { Container } from "@mui/material"

export default function FullHeightContainer({children,gap=3}) {
   const containerStyles = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      minHeight: '100%',
      gap: gap,
      p: '0 10px',
      position: 'absolute',
      top: '0',
      left: '50%',
      width: '100%',
      transform: `translateX(-50%)`
   }
   return (
      <Container sx={containerStyles}>
         {children}
      </Container>
   )
}