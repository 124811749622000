import { grey, blue } from '@mui/material/colors';
import { FilledInput, FormControl, FormHelperText, InputLabel, Container, InputAdornment, Box, Typography, IconButton, TextField, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useContext, useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { NavLink } from 'react-router-dom';
import InCenterAuth from '../wrappers/InCenterAuth';
import OnlyLogout from '../wrappers/OnlyLogout';
import { Context } from '../..';


const Login = () => {

   const { setIsAuth, logIn } = useContext(Context);
   const [showPassword, setShowPassword] = useState({ password: false, rePassword: false });

   const handleClickShowPassword = (id) => setShowPassword((show) => {
      const showCopy = { ...show };
      showCopy[id] = !showCopy[id];
      console.log(show, showCopy);
      return (showCopy)
   });

   const handleMouseDownPassword = (event) => {
      event.preventDefault();
   };

   const { handleSubmit, register, formState: { errors, isValid } } = useForm({ mode: 'onChange' })

   const onSubmit = async (data) => {
      try {
         const body = await logIn(data);
      } catch (e) {
         console.log(e);
      }
   }

   return (
      <OnlyLogout>
         <InCenterAuth>
            <Typography sx={{ textAlign: 'center', mb: 3 }} id="transition-modal-title" variant="h6" component="h2">
               Авторизуватися
            </Typography>
            <form style={{ display: 'flex', flexDirection: 'column', gap: '15px' }} onSubmit={handleSubmit(onSubmit)}>
               <TextField
                  error={!!errors.username}
                  {...register('username', {
                     required: "обов'язкове поле",
                     maxLength: {
                        value: 30,
                        message: 'максимум 30 символів'
                     }
                  })}
                  label="Логін"
                  sx={{ color: blue[700] }}
                  helperText={errors?.username && (errors?.username?.message || 'некоректні данні')}
                  variant="filled"
               />
               <FormControl error={!!errors.password} variant='filled'>
                  <InputLabel htmlFor="outlined-adornment-password">Пароль</InputLabel>
                  <FilledInput
                     {...register('password', {
                        required: "обов'язкове поле",
                        maxLength: {
                           value: 30,
                           message: 'максимум 30 символів'
                        }
                     })}
                     type={showPassword['password'] ? 'text' : 'password'}
                     id="outlined-adornment-password"
                     endAdornment={
                        <InputAdornment position="end">
                           <IconButton
                              onClick={() => handleClickShowPassword('password')}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                           >
                              {showPassword['password'] ? <VisibilityOff /> : <Visibility />}
                           </IconButton>
                        </InputAdornment>
                     }
                  />
                  <FormHelperText>{errors?.password && (errors?.password?.message || 'некоректні данні')}</FormHelperText>
               </FormControl>
               <Button disabled={!isValid} type='submit' variant="contained">Підтвердити</Button>
               <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <NavLink to={'/forgot'}>
                     <Typography sx={{ '&:hover': { color: blue[700] }, cursor: 'pointer', transition: '.2s', p: '1px' }} variant='body2' color={'text.secondary'}>
                        Забули пароль?
                     </Typography>
                  </NavLink>
                  <NavLink to={'/register'}>
                     <Typography sx={{ '&:hover': { color: blue[700] }, cursor: 'pointer', transition: '.2s', p: '1px' }} variant='body2' color={blue[900]} >
                        Зареєструватися
                     </Typography>
                  </NavLink>
               </Box>
            </form>
         </InCenterAuth>
      </OnlyLogout>
   )
}

export default Login
