import { makeAutoObservable } from 'mobx'
import $api from "../api";
import userController from '../controllers/user-controller';

class User {
   isAuth = false;
   user = {}
   constructor() {
      makeAutoObservable(this)
   }
   setUser = (value) => {
      this.user = value
   }
   setIsAuth = (value) => {
      this.isAuth = value
   }
   logIn = async(data)=>{
      try{
         const body = await userController.logIn(data)
      }catch(e){
         if(e?.response?.status === 401) return e.response.data
         throw new Response(e.message)
      }
   }
}


export default User