import { Container, Stack } from "@mui/material"
import { useLoaderData } from "react-router"
import Comments from "../components/Comments/Comments";
import BlogFullItem from "../components/Blog/BlogFullItem";


const Blog = () => {

   const { data } = useLoaderData();

   return (
      <Container sx={{ p: '0 10px' }} maxWidth={'lg'}>
         <Stack spacing={2} >
            <BlogFullItem data={data} />
            <Comments />
         </Stack>
      </Container>
   )
}
export default Blog