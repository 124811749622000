import $api from "../api";
import {encode} from 'base-64'

const userController = {
   logIn:async({username,password})=>{
      const base64String = encode(`${username}:${password}`)
      const data = await $api.get('get-token',{headers:{'Authorization':`Basic ${base64String}`}})
      return data
   }
}

export default userController