import { createContext, useEffect, useState } from "react"
import { useLoaderData } from "react-router"
import Search from "../components/Search"
import Filter from "../components/Filter"
import BlogsItems from "../components/Blog/BlogsItems"
import LeftDrawer from "../components/LeftDrawer"
import { Container, Paper, Stack, Pagination, LinearProgress, Box } from "@mui/material"
import styled from "@emotion/styled"
import { grey } from "@mui/material/colors"
import $api from "../api";
import { useSearchParams } from "react-router-dom"

const CustomizedPagination = styled(Pagination)`
   border-color:#fff;
   button{
      color:#fff !important;
      border:1px solid ${grey[700]};
   }
   div{
      color:#fff !important;
   }

`;

export const Context = createContext(null);

const Blogs = () => {

   const { data } = useLoaderData()
   const [paginationIsLoader, setPaginationIsLoader] = useState(false)
   const [BlogsData, setBlogsData] = useState({
      data: data.models,
      currentPage: data._meta.currentPage,
      pageCount: data._meta.pageCount,
   })


   const handlePaginationChange = (event, page) => {
      setPaginationIsLoader(true)
      const showAllBlogs = async () => {
         try {
            const body = await $api.get(`post?sort=-post_date&page=${page}`)
            setBlogsData({
               data: body.data.models,
               currentPage: body.data._meta.currentPage,
               pageCount: body.data._meta.pageCount,
            })
            window.scrollTo(0, 0)
            setPaginationIsLoader(false)

         } catch (e) {

         }
      }
      showAllBlogs()
   }



   return (
      <Container sx={{ maxWidth: '1200px' }}>
         <Stack spacing={3}>
            <Search />
            <Box>
               <BlogsItems Blogs={BlogsData.data}></BlogsItems>
               {paginationIsLoader
                  ?
                  <Paper sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 1, p: 0, bgcolor: grey[900] }}>
                     <LinearProgress sx={{ minWidth: '100%' }} color="secondary" />
                     <CustomizedPagination onChange={handlePaginationChange} siblingCount={1} boundaryCount={1} variant="outlined" count={BlogsData.pageCount} defaultPage={BlogsData.currentPage} color="primary" />
                     <LinearProgress sx={{ minWidth: '100%' }} color="secondary" />
                  </Paper>
                  :
                  <Paper sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 1, p: 1, bgcolor: grey[900] }}>
                     <CustomizedPagination onChange={handlePaginationChange} siblingCount={1} boundaryCount={1} variant="outlined" count={BlogsData.pageCount} defaultPage={BlogsData.currentPage} color="primary" />
                  </Paper>
               }
            </Box>
         </Stack>
      </Container >
   )
}

export default Blogs