import { useContext, useEffect } from "react"
import { Context } from "../.."
import { useNavigate } from "react-router"
import NotFound from "../../pages/NotFound"

const OnlyLogout = ({ children }) => {
   const { isAuth } = useContext(Context)
   const navigate = useNavigate()
   
   if (!isAuth) return children

   return <NotFound/>

}

export default OnlyLogout