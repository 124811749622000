import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, Divider, Grid, Rating, Link } from '@mui/material';
import { CardHeader, Avatar, IconButton, Chip } from '@mui/material';
import { NavLink } from "react-router-dom";
import style from './BlogItem.module.scss'
import { grey } from '@mui/material/colors';



export default function BlogItem({ Blog }) {


   return (
      <Card sx={{ minWidth: '100%' }}>
         <CardHeader
            avatar={
               <Avatar alt="Remy Sharp" src="/dfghjkl" />
            }
            sx={{ bgcolor: grey[900] }}
            title={<Link
               color={grey[50]}
               component="button"
               underline='none'
               id={Blog.post_author_id}
               title='author'
               sx={{
                  "&:hover": {
                     color: grey[500],
                  }
               }}
            >
               {Blog.post_author_name}
            </Link>}
            subheader={<Typography variant='body2' color={grey[500]}>{Blog.post_date}</Typography>}
         />
         <Divider />
         <CardContent>
            <Grid spacing={2} sx={{ mb: '20px' }} container columns={10}>
               <Grid item xs={10} sm={4}>
                  <CardMedia
                     sx={{ height: 180, width: '100%' }}
                     image={Blog.post_image_link || '../default.png'}
                     title="BlogImage"
                  />
               </Grid>
               <Grid item xs={10} sm={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <Typography sx={{ maxWidth: '400px' }} gutterBottom variant="h5" component="div">
                     {Blog.post_title}
                  </Typography>
                  <Box>
                     <Box sx={{ mb: 1 }}>
                        <Typography sx={{ mr: 1 }} color="text.secondary" variant='caption' component="span" gutterBottom>
                           Категорія:
                        </Typography>
                        <Button title='category' variant='contained' sx={{ borderRadius: '30px', textTransform: 'capitalize', color: grey[900], bgcolor: grey[400], '&:hover': { bgcolor: grey[500] } }} size='small' id={'1'}>
                           Математика
                        </Button>
                     </Box>
                     <Box>
                        <Typography sx={{ mr: 1 }} color="text.secondary" variant='caption' component="span" gutterBottom>
                           Теги:
                        </Typography>
                        <Button id={1} title='tag' variant='outlined' sx={{ borderRadius: '30px', textTransform: 'lowercase', lineHeight: '1', p: '6px 3px', fontSize: '10px', color: grey[600], borderColor: grey[400], '&:hover': { bgcolor: grey[200], borderColor: grey[500] } }} size='small'>
                           # числа
                        </Button>
                        <Button id={2} title='tag' variant='outlined' sx={{ borderRadius: '30px', textTransform: 'lowercase', lineHeight: '1', p: '6px 3px', fontSize: '10px', color: grey[600], borderColor: grey[400], '&:hover': { bgcolor: grey[200], borderColor: grey[500] } }} size='small'>
                           # статья
                        </Button>
                        <Button id={1} title='tag' variant='outlined' sx={{ borderRadius: '30px', textTransform: 'lowercase', lineHeight: '1', p: '6px 3px', fontSize: '10px', color: grey[600], borderColor: grey[400], '&:hover': { bgcolor: grey[200], borderColor: grey[500] } }} size='small'>
                           # числа
                        </Button>
                     </Box>
                  </Box>
               </Grid>
            </Grid>
            <Typography dangerouslySetInnerHTML={{ __html: Blog.post_content }} className={style.text} variant="body2" color="text.secondary" />
         </CardContent>
         <CardActions sx={{ display: "flex", p: 2, justifyContent: 'space-between', bgcolor: grey[900] }}>
            <NavLink to={`/Blog/${Blog.id}`}><Button variant='contained' size="big">Learn More</Button></NavLink>
            <Rating sx={{
               '& .MuiRating-iconEmpty': {
                  color: grey[700],
               },
            }} name="read-only" value={3} readOnly />
         </CardActions>
      </Card>
   );
}