import { grey, blue } from '@mui/material/colors';
import { FormControl, Box, Typography, TextField, Button, Container } from '@mui/material';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import InCenterAuth from '../wrappers/InCenterAuth';
import OnlyLogout from '../wrappers/OnlyLogout';

const Forgot = () => {

   const { handleSubmit, register, formState: { errors, isValid } } = useForm({ mode: 'onChange' })

   const onSubmit = (data) => {
   }

   return (
      <OnlyLogout>
         <InCenterAuth>
            <Box sx={{ textAlign: 'center', mb: 3 }}>
               <Typography id="transition-modal-title" sx={{ mb: 1 }} variant="h5" component="h2">
                  Забули пароль?
               </Typography>
               <Typography id="transition-modal-title" color={'text.secondary'} variant="body2" component="h2">
                  Напишіть вашу електронну пошту
               </Typography>
               <Typography id="transition-modal-title" color={'text.secondary'} variant="body2" component="h2">
                  ми надішлемо вам посилання для зміни паролю
               </Typography>

            </Box>
            <form style={{ display: 'flex', flexDirection: 'column', gap: '15px' }} onSubmit={handleSubmit(onSubmit)}>
               <TextField
                  error={!!errors.email}
                  {...register('email', {
                     required: "обов'язкове поле",
                     pattern: {
                        value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                        message: 'пошта має бути формату - example@mail.com'
                     }
                  })}
                  label="Пошта"
                  sx={{ color: blue[700] }}
                  helperText={errors?.email && (errors?.email?.message || 'некоректні данні')}
                  variant="filled"
               />
               <Button disabled={!isValid} type='submit' variant="contained">Надіслати</Button>
               <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <NavLink to={'/login'}>
                     <Typography sx={{ '&:hover': { color: blue[700] }, cursor: 'pointer', transition: '.2s', p: '1px' }} variant='body2' color={blue[900]}>
                        Авторизуватися
                     </Typography>
                  </NavLink>
                  <NavLink to={'/register'}>
                     <Typography sx={{ '&:hover': { color: blue[700] }, cursor: 'pointer', transition: '.2s', p: '1px' }} variant='body2' color={blue[900]} >
                        Зареєструватися
                     </Typography>
                  </NavLink>
               </Box>
            </form>
         </InCenterAuth>
      </OnlyLogout>
   )
}

export default Forgot
