import { Typography } from "@mui/material"
import { NavLink } from "react-router-dom"
import { Button } from "@mui/material"
import BungalowIcon from '@mui/icons-material/Bungalow';
import FullHeightContainer from "../components/FullHeightContainer"

export default function NotFound({ }) {
   return (
      <FullHeightContainer>
         <Typography variant="h3">
            Такої сторінки не існує
         </Typography>
         <NavLink to={'/'}>
            <Button size='large' endIcon={<BungalowIcon />} variant="contained">
               на головну
            </Button>
         </NavLink>
      </FullHeightContainer>
   )
}