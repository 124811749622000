import BlogItem from "./BlogItem"
import { Stack, } from "@mui/material"

const BlogsItems = ({Blogs}) => {
   return (
      <Stack spacing={2}>
      {Blogs && Blogs.map((el,i) =><BlogItem key={el.id} Blog={el} />)}
   </Stack>
   )
}

export default BlogsItems