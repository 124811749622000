import { Paper, Link, Button, Card, CardContent, CardMedia, Typography, Box, Divider, Stack } from "@mui/material"
import StartRating from "../StarsRating"
import BreadcrumbsComponent from "../Comments/BreadcrumbsComponent"
import { grey } from "@mui/material/colors"
import { NavLink } from "react-router-dom"

const BlogFullItem = ({ data }) => {
   console.log(data);
   return (
      <Paper>
         <Card>
            <BreadcrumbsComponent category={{ id: data.post_categories.id, name: data.post_categories.name }} title={data.post_title} />
            <CardContent sx={{ p: '0 20px 20px 20px' }}>
               <CardMedia
                  sx={{ aspectRatio: { xs: '2/1', sm: '3/1', md: '4/1' }, width: '100%' }}
                  image={data.post_image_link || '../default.png'}
                  title="BlogImage"
               />
               <Typography sx={{ mt: 2, mb: 2 }} variant='h4'>
                  {data.post_title}
               </Typography>
               <Typography dangerouslySetInnerHTML={{ __html: data.post_content }} sx={{ mb: 3, lineHeight: '170%' }} variant="body1" color="text.secondary">
               </Typography>
            </CardContent>
            <Stack  sx={{ bgcolor: grey[900]}}>
               <Box sx={{ display: 'flex', p: 2 , justifyContent: 'space-between', alignItems: 'end', gap: '25px', flexWrap: 'wrap' }}>
                  <Box>
                     <Typography sx={{ mr: 1 }} color={grey[50]} variant='caption' component="span" gutterBottom>
                        Автор:
                     </Typography>
                     <NavLink to={'/Blog?author=2'}>
                        <Link color={grey[400]}
                           component="button"
                           underline='none'
                           sx={{
                              "&:hover": {
                                 color: grey[50],
                              }
                           }} >
                           {data.post_author_name}
                        </Link>
                     </NavLink>
                  </Box>
                  <Typography variant="body2" color={grey[500]} >{data.post_date}</Typography>
               </Box>
               <Divider sx={{bgcolor:grey[800]}}/>
               <Box sx={{ display: 'flex', p: 2 , justifyContent: 'space-between', alignItems: 'end', gap: '25px', flexWrap: 'wrap' }}>
                  <Box>
                     <Box sx={{ mb: 1 }}>
                        <Typography sx={{ mr: 1 }} color={grey[50]} variant='caption' component="span" gutterBottom>
                           Категорія:
                        </Typography>
                        <NavLink to={'/Blog?category=1'}>
                           <Button variant='contained' sx={{ borderRadius: '30px', textTransform: 'capitalize', p: '1px 5px', color: grey[900], bgcolor: grey[50], '&:hover': { bgcolor: grey[400] } }} >
                              Математика
                           </Button>
                        </NavLink>
                     </Box>
                     <Box>
                        <Typography sx={{ mr: 1 }} color={grey[50]} variant='caption' component="span" gutterBottom>
                           Теги:
                        </Typography>
                        <NavLink to={'/Blog?tag=1'}>
                           <Button variant='outlined' sx={{ borderRadius: '30px', textTransform: 'lowercase', lineHeight: '1', p: '6px 3px', fontSize: '10px', color: grey[600], borderColor: grey[400], '&:hover': { bgcolor: grey[800], borderColor: grey[500] } }} size='small'>
                              # числа
                           </Button>
                        </NavLink>
                        <NavLink to={'/Blog?tag=1'}>
                           <Button variant='outlined' sx={{ borderRadius: '30px', textTransform: 'lowercase', lineHeight: '1', p: '6px 3px', fontSize: '10px', color: grey[600], borderColor: grey[400], '&:hover': { bgcolor: grey[800], borderColor: grey[500] } }} size='small'>
                              # числа
                           </Button>
                        </NavLink>
                        <NavLink to={'/Blog?tag=1'}>
                           <Button variant='outlined' sx={{ borderRadius: '30px', textTransform: 'lowercase', lineHeight: '1', p: '6px 3px', fontSize: '10px', color: grey[600], borderColor: grey[400], '&:hover': { bgcolor: grey[800], borderColor: grey[500] } }} size='small'>
                              # числа
                           </Button>
                        </NavLink>
                     </Box>
                  </Box>
                  <StartRating value={3}></StartRating>
               </Box>
            </Stack>
         </Card>
      </Paper>
   )
}

export default BlogFullItem
