import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Box } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';

export default function Search() {
   const [anchorEl, setAnchorEl] = React.useState(null);
   const open = Boolean(anchorEl);
   const handleClose = () => {
      setAnchorEl(false);
   };
   const handleOpenMenu = (event) => {
      setAnchorEl(event.currentTarget);

   }
   return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
         <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '600px' }}
         >
            <IconButton onClick={handleOpenMenu} sx={{ p: '10px' }} aria-label="menu">
               <MenuIcon />
            </IconButton>
            <Menu
               id="menu-appbar"
               anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
               }}
               keepMounted
               transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
               }}
               open={open}
               onClose={handleClose}
            >
               <MenuItem >
                     <Typography textTransform='capitalize' textAlign="center">hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh</Typography>
               </MenuItem>
            </Menu>
            <InputBase
               sx={{ ml: 1, flex: 1 }}
               placeholder="Пошук"
               inputProps={{ 'aria-label': 'Пошук' }}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
               <SearchIcon />
            </IconButton>

         </Paper>
      </Box>
   );
}