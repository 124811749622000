import * as React from 'react';
import { MenuItem, Grid, Tooltip, Button, Avatar, Container, Menu, Typography, IconButton, Toolbar, Box, AppBar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { grey } from '@mui/material/colors';
import { Context } from '..';
import { NavLink } from "react-router-dom";
import {observer} from 'mobx-react-lite'

const settings = ['Profile', 'Dashboard', 'Logout'];

function Header() {
   const { isAuth } = React.useContext(Context)
   console.log(isAuth);

   const [anchorElNav, setAnchorElNav] = React.useState(null);
   const [anchorElUser, setAnchorElUser] = React.useState(null);

   const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
   };
   const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
   };

   const handleCloseNavMenu = () => {
      setAnchorElNav(null);
   };

   const handleCloseUserMenu = () => {
      setAnchorElUser(null);
   };

   return (
      <AppBar sx={{ bgcolor: grey[900] }} position="static">
         <Container maxWidth="xl">
            <Grid container alignItems={'center'}>
               <Grid item sx={{display: { xs: 'flex', md: 'none' } }}>
                  <IconButton
                     size="large"
                     aria-label="account of current user"
                     aria-controls="menu-appbar"
                     aria-haspopup="true"
                     onClick={handleOpenNavMenu}
                     color="inherit"
                  >
                     <MenuIcon />
                  </IconButton>
                  <Menu
                     id="menu-appbar"
                     anchorEl={anchorElNav}
                     anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                     }}
                     keepMounted
                     transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                     }}
                     open={Boolean(anchorElNav)}
                     onClose={handleCloseNavMenu}
                     sx={{
                        display: { xs: 'block', md: 'none' },
                     }}
                  >
                     <MenuItem onClick={handleCloseNavMenu}>
                        <NavLink to={`/`}>
                           <Typography textTransform='capitalize' textAlign="center">Головна</Typography>
                        </NavLink>
                     </MenuItem>
                     <MenuItem onClick={handleCloseNavMenu}>
                        <NavLink to={`/Blog`}>
                           <Typography textTransform='capitalize' textAlign="center">Блог</Typography>
                        </NavLink>
                     </MenuItem>
                  </Menu>
               </Grid>
               <Grid sx={{ display: { xs: 'none', md: 'flex' } }} item xs>
                  <NavLink to={`/`}>
                     <Button onClick={handleCloseNavMenu} sx={{ my: 2, color: 'white', display: 'block' }}>Головна</Button>
                  </NavLink>
                  <NavLink to={`/Blog`}>
                     <Button onClick={handleCloseNavMenu} sx={{ my: 2, color: 'white', display: 'block' }}>Блог</Button>
                  </NavLink>
               </Grid>
               <Grid sx={{ display: { xs: 'none', md: 'flex' }, }} item>
                  <NavLink to='/'>
                     <Typography variant="h6" sx={{ fontFamily: 'monospace', fontWeight: 700, letterSpacing: '.3rem', color: grey[500], }}>LOGO</Typography>
                  </NavLink>
               </Grid>
               <Grid sx={{ display: 'flex', justifyContent: 'end' }} item xs>
                  {isAuth
                     ?
                     <>
                        <Tooltip title="Open settings">
                           <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                              <Avatar alt="Remy Sharp" src="/dfghjkl" />
                           </IconButton>
                        </Tooltip>
                        <Menu
                           sx={{ mt: '45px' }}
                           id="menu-appbar"
                           anchorEl={anchorElUser}
                           anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                           }}
                           keepMounted
                           transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                           }}
                           open={Boolean(anchorElUser)}
                           onClose={handleCloseUserMenu}
                        >
                           {settings.map((setting) => (
                              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                 <Typography textAlign="center">{setting}</Typography>
                              </MenuItem>
                           ))}
                        </Menu></>
                     :
                     <nav style={{ display: 'flex', gap: '10px' }}>
                        <NavLink to={'/login'}>
                           <Button variant='contained'>Увійти</Button>
                        </NavLink>
                        <NavLink to={'/register'}>
                           <Button variant='outlined'>Реєстрація</Button>
                        </NavLink>
                     </nav>
                  }
               </Grid>
            </Grid>
         </Container>
      </AppBar>
   );
}
export default observer(Header);