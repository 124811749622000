import { Paper, Pagination, Typography, Box, Divider, Stack } from "@mui/material"
import { styled } from "@mui/material";
import { grey, blue } from '@mui/material/colors';
import Comment from './Comment';

const CustomizedPagination = styled(Pagination)`
   border-color:#fff;
   button{
      color:#fff !important;
      border:1px solid ${grey[700]};
   }
   div{
      color:#fff !important;
   }

`;

const Comments = () => {
   return (
      <Paper sx={{ bgcolor: grey[700] }}>
         <Typography sx={{ p: '20px 20px', bgcolor: grey[900] }} color={grey[50]} variant="h4">
            Коментарі
         </Typography>
         <Stack sx={{ p: '20px 0', }} spacing={2} divider={<Divider color={grey[400]} sx={{ minWidth: '100%' }} />}>
            {Array(9).fill(1).map((el,i) => <Comment key={i} />)}
         </Stack>
         <Box sx={{ bgcolor: grey[900], display: 'flex', justifyContent: 'center' }}>
            <CustomizedPagination count={10} variant="outlined" color="primary" sx={{ p: 1, }}></CustomizedPagination>
         </Box>
      </Paper>
   )
}

export default Comments