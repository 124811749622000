import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import { Box, Divider, Typography, Button } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { grey } from '@mui/material/colors';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { Context } from '../pages/Blogs';

const style = {
   flex: '1',
   overflowY: 'scroll',
   "&::-webkit-scrollbar": { width: '8px' },
   "&::-webkit-scrollbar-track": { borderRadius: '2px', },
   "&::-webkit-scrollbar-thumb": { bgcolor: grey[400], borderRadius: '10px', border: '2px solid #fff' },
}

export default function Filter() {

   const {checkboxes,handleChange,handleClick} = React.useContext(Context);
   
   let isDisabled = true

   for (let key in checkboxes){
      if(checkboxes[key].find(el=>el.checked)){
         isDisabled = false
         break 
      }
   }


   return (
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
         <Typography sx={{ bgcolor: grey[900], p: "20px" }} color={grey[50]} variant='h5'>Фільтрувати</Typography>
         <Box sx={style}>
            <Typography sx={{ fontSize: '13px', padding: "10px 20px" }} component="div" id="nested-list-subheader">
               по категоріям
            </Typography>
            <Divider />
            <FormGroup onChange={handleChange} sx={{ p: '20px' }}>
               {checkboxes.category.map(el => <FormControlLabel key={el.id} control={<Checkbox id='category' checked={el.checked} value={el.id} />} label={el.name} />)}
            </FormGroup>
            <Typography sx={{ fontSize: '13px', padding: "10px 20px" }} component="div" id="nested-list-subheader">
               по тегам
            </Typography>
            <Divider />
            <FormGroup onChange={handleChange} sx={{ p: '20px' }}>
               {checkboxes.tag.map(el => <FormControlLabel key={el.id} control={<Checkbox id='tag' checked={el.checked} value={el.id} />} label={el.name} />)}
            </FormGroup>
            <Typography sx={{ fontSize: '13px', padding: "10px 20px" }} component="div" id="nested-list-subheader">
               по авторам
            </Typography>
            <Divider />
            <FormGroup onChange={handleChange} sx={{ p: '20px' }}>
               {checkboxes.author.map(el => <FormControlLabel key={el.id} control={<Checkbox id='author' checked={el.checked} value={el.id} />} label={el.name} />)}
            </FormGroup>
         </Box>
         <Box sx={{ bgcolor: grey[900], p: 2 }}>
            <Button disabled = {isDisabled} startIcon={<DoNotDisturbOnIcon />} onClick={handleClick} sx={{
               display: 'flex', width: '100%', "&.Mui-disabled": {
                  background: grey[700],
                  color: "#c0c0c0"
               }
            }} variant={'contained'}>скинути все</Button>
         </Box>
      </Box>
   );
}