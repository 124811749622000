import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { grey } from '@mui/material/colors';
import { NavLink } from 'react-router-dom';

const BreadcrumbsComponent = ({ title,category }) => {

   return (
      <Breadcrumbs color={grey[400]} sx={{ bgcolor: grey[900], p: 3 }} aria-label="breadcrumb">
         <NavLink to={'/Blog'}>
            <Link
               color={grey[400]}
               component="button"
               underline='none'
               sx={{
                  "&:hover": {
                     color: grey[50],
                  }
               }}
            >
               Блог
            </Link>
         </NavLink>
         <NavLink to={`/Blog?category=1`}>
            <Link
               color={grey[400]}
               component="button"
               underline='none'
               sx={{
                  "&:hover": {
                     color: grey[50],
                  }
               }}
            >
               Математика
            </Link>
         </NavLink>
         <Typography color={grey[50]}>
            {title}
         </Typography>
      </Breadcrumbs >)
}

export default BreadcrumbsComponent