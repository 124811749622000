import axios from 'axios'
import Config from '../config'


const $api = axios.create({
   baseURL: Config.API_URL,
   withCredentials: true,
   headers: {
      'Content-Type': 'application/json'

   }
})

export default $api