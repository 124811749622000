import { blue } from '@mui/material/colors';
import { FilledInput, FormControl, FormHelperText, InputLabel, InputAdornment, Box, Typography, IconButton, TextField, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { NavLink } from 'react-router-dom';
import InCenterAuth from '../wrappers/InCenterAuth';
import OnlyLogout from '../wrappers/OnlyLogout';


const Register = () => {

   const { handleSubmit, register, getValues, formState: { errors, isValid } } = useForm({ mode: 'onChange' })
   const [showPassword, setShowPassword] = useState({ password: false, rePassword: false });

   const handleClickShowPassword = (id) => setShowPassword((show) => {
      const showCopy = { ...show };
      showCopy[id] = !showCopy[id];
      console.log(show, showCopy);
      return (showCopy)
   });

   const handleMouseDownPassword = (event) => {
      event.preventDefault();
   };

   const onSubmit = (data) => {
      console.log(data);
   }

   return (
      <OnlyLogout>
         <InCenterAuth>
            <Typography sx={{ textAlign: 'center', mb: 3 }} id="transition-modal-title" variant="h6" component="h2">
               Реєстрація
            </Typography>
            <form style={{ display: 'flex', flexDirection: 'column', gap: '15px' }} onSubmit={handleSubmit(onSubmit)}>
               <TextField
                  error={!!errors.username}
                  {...register('username', {
                     required: "обов'язкове поле",
                     minLength: {
                        value: 3,
                        message: 'мінімум 3 символи'
                     },
                     maxLength: {
                        value: 20,
                        message: 'максимум 20 символів'
                     }
                  })}
                  label="Логін"
                  sx={{ color: blue[700] }}
                  helperText={errors?.username && (errors?.username?.message || 'некоректні данні')}
                  variant="filled"
               />
               <TextField
                  error={!!errors.email}
                  {...register('email', {
                     required: "обов'язкове поле",
                     pattern: {
                        value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                        message: 'пошта має бути формату - example@mail.com'
                     }
                  })}
                  label="Пошта"
                  sx={{ color: blue[700] }}
                  helperText={errors?.email && (errors?.email?.message || 'некоректні данні')}
                  variant="filled"
               />
               <TextField
                  error={!!errors.given_name}
                  {...register('given_name', {
                     required: "обов'язкове поле",
                     minLength: {
                        value: 3,
                        message: 'мінімум 3 символи'
                     },
                     maxLength: {
                        value: 30,
                        message: 'максимум 30 символів'
                     }
                  })}
                  label="Ім'я"
                  sx={{ color: blue[700] }}
                  helperText={errors?.given_name && (errors?.given_name?.message || 'некоректні данні')}
                  variant="filled"
               />
               <TextField
                  error={!!errors.patroymic_name}
                  {...register('patroymic_name', {
                     required: "обов'язкове поле",
                     minLength: {
                        value: 3,
                        message: 'мінімум 3 символи'
                     },
                     maxLength: {
                        value: 30,
                        message: 'максимум 30 символів'
                     }
                  })}
                  label="ім'я по батькові"
                  sx={{ color: blue[700] }}
                  helperText={errors?.patroymic_name && (errors?.patroymic_name?.message || 'некоректні данні')}
                  variant="filled"
               />
               <TextField
                  error={!!errors.family_name}
                  {...register('family_name', {
                     required: "обов'язкове поле",
                     minLength: {
                        value: 3,
                        message: 'мінімум 3 символи'
                     },
                     maxLength: {
                        value: 30,
                        message: 'максимум 30 символів'
                     }
                  })}
                  label="Прізвище"
                  sx={{ color: blue[700] }}
                  helperText={errors?.family_name && (errors?.family_name?.message || 'некоректні данні')}
                  variant="filled"
               />
               <FormControl error={!!errors.password} variant='filled'>
                  <InputLabel htmlFor="outlined-adornment-password">Пароль</InputLabel>
                  <FilledInput
                     {...register('password', {
                        required: "обов'язкове поле",
                        minLength: {
                           value: 7,
                           message: 'мінімум 7 символи'
                        },
                        deps: ['rePassword'],
                        maxLength: {
                           value: 30,
                           message: 'максимум 30 символів'
                        }
                     })}
                     type={showPassword['password'] ? 'text' : 'password'}
                     id="outlined-adornment-password"
                     endAdornment={
                        <InputAdornment position="end">
                           <IconButton
                              onClick={() => handleClickShowPassword('password')}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                           >
                              {showPassword['password'] ? <VisibilityOff /> : <Visibility />}
                           </IconButton>
                        </InputAdornment>
                     }
                  />
                  <FormHelperText>{errors?.password && (errors?.password?.message || 'некоректні данні')}</FormHelperText>
               </FormControl>
               <FormControl error={!!errors.rePassword} variant='filled'>
                  <InputLabel htmlFor="outlined-adornment-rePassword">Введіть пароль ще раз</InputLabel>
                  <FilledInput
                     {...register('rePassword', {
                        required: "обов'язкове поле",
                        maxLength: {
                           value: 30,
                           message: 'максимум 30 символів'
                        },
                        validate: v => getValues('password') === v,
                     })}
                     type={showPassword['rePassword'] ? 'text' : 'password'}
                     id="outlined-adornment-rePassword"
                     endAdornment={
                        <InputAdornment position="end">
                           <IconButton
                              onClick={() => handleClickShowPassword('rePassword')}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                           >
                              {showPassword['rePassword'] ? <VisibilityOff /> : <Visibility />}
                           </IconButton>
                        </InputAdornment>
                     }
                  />
                  <FormHelperText>{errors?.rePassword && (errors?.rePassword?.message || 'паролі не співпадають')}</FormHelperText>
               </FormControl>
               <Button disabled={!isValid} type='submit' variant="contained">Надіслати</Button>
               <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <NavLink to={'/forgot'}>
                     <Typography sx={{ '&:hover': { color: blue[700] }, cursor: 'pointer', transition: '.2s', p: '1px' }} variant='body2' color={'text.secondary'}>
                        Забули пароль?
                     </Typography>
                  </NavLink>
                  <NavLink to={'/login'}>
                     <Typography sx={{ '&:hover': { color: blue[700] }, cursor: 'pointer', transition: '.2s', p: '1px' }} variant='body2' color={blue[900]} >
                        Авторизуватися
                     </Typography>
                  </NavLink>
               </Box>
            </form>
         </InCenterAuth>
      </OnlyLogout>
   )
}

export default Register
