import { Card, CardContent, Typography, Box, CardHeader, Avatar} from "@mui/material"
import { grey, blue } from '@mui/material/colors';

const Comment = () => {
   return (
      <Box sx={{ display: 'flex', alignItems: 'end', justifyContent: 'start', gap: 1, p: { sm: '0 20px' } }}>
         <Card sx={{ display: { xs: 'none', sm: 'inline-block', }, flex: "0 0 auto" }}>
            <CardHeader
               sx={{ display: 'inline-flex', p: 1 }}
               avatar={
                  <Avatar aria-label="recipe" sx={{ bgcolor: blue[300] }} src='default.png' />
               }
               title='Shrimp and Chorizo Paella'
               subheader='September 14, 2016'
            />
         </Card>
         <Card sx={{ borderRadius: { sm: '50px 50px 50px 0px' }, background: blue[400] }}>
            <CardHeader
               sx={{ display: { sm: 'none' }, p: 1, bgcolor: grey[50] }}
               avatar={
                  <Avatar aria-label="recipe" sx={{ bgcolor: blue[300] }} src='default.png' />
               }
               title='Shrimp and Chorizo Paella'
               subheader='September 14, 2016'
            />
            <CardContent sx={{ p: { sm: 3 } }}>
               <Typography variant={'subtitle1'} color={grey[50]}>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione, deserunt. Modi ullam minima debitis laboriosam. Cupiditate optio, quod excepturi nisi placeat adipisci, dolorum explicabo dolore mollitia consequuntur beatae blanditiis, voluptatibus dicta ad nemo odio ex accusamus itaque et soluta! Ipsam itaque hic dicta odit nostrum repudiandae facere in debitis deleniti perspiciatis eaque, reprehenderit corporis amet. Possimus dignissimos totam fugit temporibus dolorem rem nulla nam, dolore nisi delectus unde ab incidunt tempora in suscipit dolor sequi vero exercitationem quisquam sint natus neque deserunt recusandae! Earum, vero harum, omnis velit debitis odio voluptas accusamus saepe aliquid, sed deserunt eaque laboriosam? Qui, sunt.
               </Typography>
            </CardContent>
         </Card>
      </Box>
   )
}

export default Comment